import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchEmailNotifications(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/people", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchEmailNotificationStatus() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/people-status")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchEmailNotification(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`people/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addEmailNotification(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("people", userData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateFavorite(ctx, user_data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/people/add-favorite/`, { hashid: user_data.id })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateAllSubscription(ctx, user_data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/settings/email-subscriptions/all`, {
                        subscribed: user_data.subscribed
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeEmailNotification(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`people/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleEmailNotifications(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/deleteMultipleEmailNotifications/`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/people/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        getPeople(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/people",queryParams )
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        }
    }
};
